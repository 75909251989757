@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

@font-face {
    font-family: 'JSArirang-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/JSArirang-RegularA1.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.noise {
    user-select: none;
    pointer-events: none;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('elements/noise.jpg');
    mix-blend-mode: multiply;
    opacity: 0.05;
}

:root {
    --color-gray: Gainsboro;
    --color-red: IndianRed;
    --color-green: DarkSeaGreen;
    --color-blue: CornflowerBlue;
    --color-yellow: LemonChiffon;
}

* {
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Noto Sans KR', sans-serif;
}

a,
ul,
li {
    all: unset;
}

i {
    pointer-events: none;
}

u {
    box-shadow: inset 0 -.75rem 0 var(--color-yellow);
}

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#root {
    width: 100%;
    height: 100%;

    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@keyframes fadeIn {
    from {
        transform: translateY(4rem);
        opacity: 0;
    }

    to {
        transform: none;
        opacity: 1;
    }
}