#history {
    position: relative;
    padding-bottom: 2rem;
    min-height: 100%;
}

#history .container {
    position: relative;
    margin: auto;
    width: fit-content;
    max-width: calc(100% - 2rem);
}

#history .item {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    opacity: 0;
    animation: fadeIn 500ms ease-in forwards;
}

#history .line {
    width: 1px;
    background-color: black;
    flex-shrink: 0;
}

#history .line.end {
    background: linear-gradient(to bottom, black, transparent);
}

#history .line .icon {
    width: 1px;
    height: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    animation-duration: 1500ms;
}

#history .year {
    flex-shrink: 0;
    line-height: 100%;
    font-size: xx-large;
}

#history .year:not(.first) {
    opacity: 0;
}

#history .month {
    flex-shrink: 0;
    font-size: large;
}

#history .card {
    word-break: keep-all;
}

#history .card .title {
    font-size: large;
}

#history .card .text {
    margin-bottom: 1rem;
}

#history .card .text.end {
    margin-bottom: 4rem;
}

@media (max-width: 45rem) {
    #history .year {
        font-size: x-large;
    }

    #history .month,
    #history .card .title {
        font-size: medium;
    }

    #history .card .text {
        font-size: small;
    }
}