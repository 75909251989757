#news {
    position: relative;
    padding-bottom: 2rem;
    min-height: 100%;
}

#news .container {
    margin: auto;
    width: calc(100% - 2rem);
    max-width: 80rem;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

#news .item {
    cursor: pointer;
    position: relative;
    padding: 2rem;
    min-height: 50dvh;

    border: solid 1px var(--color-gray);

    opacity: 0;
    animation: fadeIn 500ms ease-in forwards;

    transition: 500ms;
}

#news .item.more {
    padding: 1rem;
    min-height: fit-content;

    text-align: center;
    grid-column: span 3;
}

#news .item:hover,
#news .item.active {
    z-index: 3;
    background-color: white;
    border-color: black;
    box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
}

#news .item .icon {
    height: 2rem;
}

#news .item .title {
    font-size: large;
    word-break: keep-all;
    white-space: balance;
    text-align: center;
}

#news .item .time {
    margin: 1rem 0;
    font-size: small;
    text-align: center;
}

#news .item .link {
    text-decoration: underline;
}


@media (max-width: 82rem) {
    #news .container {
        grid-template-columns: repeat(2, 1fr);
    }

    #news .item.more {
        grid-column: span 2;
    }
}

@media (max-width: 45rem) {
    #news .container {
        grid-template-columns: repeat(1, 1fr);
    }

    #news .item:not(.more) {
        z-index: 3;
        background-color: white;
        border-color: black;
        box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
    }

    #news .item.more {
        grid-column: span 1;
    }
}