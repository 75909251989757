#project {
    position: relative;
    padding-bottom: 2rem;
    min-height: 100%;
}

#project .container {
    margin: auto;
    width: calc(100% - 2rem);
    max-width: 80rem;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

#project .item {
    cursor: pointer;
    position: relative;
    padding: 2rem;
    height: 20rem;

    color: white;
    border: solid 1px var(--color-gray);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    opacity: 0;
    animation: fadeIn 500ms ease-in forwards;

    overflow: hidden;

    transition: 500ms;
}

#project .item.active {
    cursor: auto;
    padding: 0;
    margin: auto;
    width: calc(100% - 2rem);
    max-width: 80rem;
    height: auto;

    border: none;

    align-items: flex-start;
}

#project .item.hide {
    display: none;
}

#project .item:not(.active):hover {
    box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
}

#project .item .img {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
    filter: brightness(.5);

    transition: 1000ms;
}

#project .item.active .img {
    position: static;
    filter: none;
}

#project .item:not(.active):hover .img {
    transform: scale(1.2);
}

#project .item .title {
    line-height: 2rem;
    font-size: large;
}

#project .item .title .hr {
    width: 0;
    height: 1px;
    background-color: white;

    transition: 1000ms;
}

#project .item:hover .title .hr {
    width: 100%;
}

#project .item .process {
    position: absolute;
    top: 2rem;
    left: 2rem;
    padding: .25rem;
    border: solid 1px white;
    font-size: small;

    transition: 500ms;
}

#project .item .text {
    color: black;
}

#project .clsBtn {
    cursor: pointer;
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
}

@media (max-width: 82rem) {
    #project .container {
        grid-template-columns: repeat(2, 1fr);
    }

    #project .item {
        height: 25rem;
    }
}

@media (max-width: 45rem) {
    #project .container {
        grid-template-columns: repeat(1, 1fr);
    }

    #project .item {
        height: 50dvh;
    }
}