.sectionTitle {
    position: relative;
    padding: 6rem 1rem 4rem;

    text-align: center;
    word-break: keep-all;
    white-space: balance;

    overflow: hidden;
    flex-shrink: 0;
}

.sectionTitle ._title {
    font-size: xx-large;
}