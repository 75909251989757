#root:has(.nav.main) {
    overflow: hidden;
}

.section {
    position: relative;
    width: 100%;
    height: 100%;

    transition: 1000ms;
}

.section .img {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: 1000ms;
    opacity: 0;
}

.section .img.show {
    opacity: 1;
}

.section .swiper {
    width: 100%;
    height: 100%;
}

.section.main .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.section.main .img {
    object-position: 80% top;
}

.section.main .video {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    filter: brightness(.9);
}

.section.main .text {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 4rem 2rem;
    width: calc(100% - 2rem);
    max-width: 60rem;

    transform: translate(-50%, -50%);

    color: white;
    backdrop-filter: blur(.25rem) brightness(.9);
    border: solid 1px rgba(255, 255, 255, .2);

    text-align: center;
    word-break: keep-all;
    white-space: balance;
}

.section.main .text .bold {
    font-size: xx-large;

    opacity: 0;
    animation: fadeIn 500ms ease-in forwards;

    transition: 500ms;
}

.section.main .text span {
    line-height: 2rem;
    font-family: 'JSArirang-Regular', sans-serif;
    font-size: xx-large;
}

.section.service a {
    cursor: pointer;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
}

.section.service .item {
    position: relative;
    padding: 2rem;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    color: white;
    text-align: center;
    word-break: keep-all;
    white-space: balance;

    overflow: hidden;
}

.section.service .item .img {
    filter: brightness(.5);

    transition: 1500ms;
}

.section.service .item:hover .img {
    transform: scale(1.2);
}

.section.service .item .title {
    font-size: large;
}

.section.project .swiper-btn {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: 0;
    width: 4rem;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
}

.section.project .swiper-btn.hide {
    opacity: 0;
}

.section.project .swiper-btn.prev {
    left: 0;
}

.section.project .swiper-btn.next {
    right: 0;
}

.section.project .swiper-slide {
    position: relative;
    cursor: pointer;
    padding: 4rem;

    color: white;
    border-left: solid 1px rgba(0, 0, 0, .5);

    transition: 1000ms;
}

.section.project .swiper-slide:hover {
    backdrop-filter: brightness(.5);
}

.section.project .swiper:has(.swiper-slide:hover) .swiper-slide {
    border-color: rgba(255, 255, 255, .5);
}

.section.project .swiper-slide a {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.section.project .swiper-slide .img {
    filter: brightness(.5);
}

.section.project .swiper:has(.swiper-slide:hover) .img {
    opacity: 0;
    filter: none;
}

.section.project .swiper-slide .title {
    width: 100%;
    line-height: 2rem;
    font-size: large;
}

.section.project .swiper-slide .title .hr {
    width: 0;
    height: .5px;
    background-color: white;

    transition: 1000ms;
}

.section.project .swiper-slide:hover .title .hr {
    width: 100%;
}

.section.project .swiper-slide .process {
    padding: .25rem;
    border: solid 1px white;
    font-size: small;
}

.section.project .swiper-slide .text {
    font-size: small;
    font-weight: 200;

    transition: 1000ms;
    opacity: 0;
}

.section.project .swiper-slide:hover .text {
    opacity: 1;
}

@media (orientation: portrait) {
    .section.service a {
        flex-direction: column;
    }
}

@media (max-width: 45rem) {
    .section.main .video {
        object-position: calc(100% / 3 * 2) 50%;
    }

    .section.project .swiper-btn {
        display: none;
    }

    .section.project .swiper-slide {
        padding: 2rem;
        border-width: 0;
    }

    .section.project .img {
        opacity: 0;
    }

    .section.project .swiper-slide .img {
        opacity: 1 !important;
        filter: brightness(.5) !important;
    }

    .section.project .swiper-slide .title .hr {
        width: 100%;
    }

    .section.project .swiper-slide .text {
        opacity: 1;
    }
}