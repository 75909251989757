.nav {
    z-index: 4;
    position: fixed;

    width: 100%;
    border-bottom: solid 1px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    overflow: hidden;

    transition: 500ms;
    height: 4rem;
    border-color: transparent;
}

.nav.main {
    color: white;
}

.nav.main:not(:hover) .noise {
    display: none;
}

.nav:hover {
    height: 14rem;
    color: black;
    background-color: white;
    border-color: var(--color-gray);
}

.nav:not(.main) {
    background-color: white;
    border-color: var(--color-gray);
}

.nav #logo {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1.5rem;
}

.nav #logo img {
    margin-right: .5rem;
    height: 2rem;

    transition: 500ms;
}

.nav.main #logo img {
    filter: invert(1);
}

.nav:hover #logo img {
    filter: invert(0);
}

.nav .bars {
    display: none;
}

.nav ul {
    display: flex;
    flex-direction: row;
}

.nav li {
    cursor: pointer;
    position: relative;
    margin: 1rem;
    padding-bottom: 0;
    height: fit-content;
    text-align: center;

    line-height: 2rem;
}

.nav .links {
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
}

.nav .links li {
    position: relative;
    margin: 0;
    width: fit-content;
}

.nav .hr {
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: .25rem;
    height: .75rem;
    background-color: var(--color-yellow);

    transition: 500ms;
    width: 0;
}

.nav li:hover>.hr {
    width: 100%;
}

@media (max-width: 45rem) {
    .nav {
        justify-content: flex-end;
    }

    .nav:hover {
        height: 4rem;
    }

    .nav:has(.active) {
        height: 100%;
        color: black;
        background-color: white;
        border-color: var(--color-gray);
    }

    .nav:has(.active) #logo img {
        filter: invert(0);
    }

    .nav .bars {
        cursor: pointer;
        width: 4rem;
        height: 4rem;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav ul {
        flex-direction: column;
        align-items: flex-end;
    }

    .nav .links {
        align-items: flex-end;
    }
}