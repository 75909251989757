#contact {
    position: relative;
    padding-bottom: 2rem;
    min-height: 100%;
}

#contact .map {
    cursor: pointer;
    position: relative;
    margin: auto;
    width: calc(100% - 2rem);
    max-width: 80rem;
    height: 50dvh;

    border: solid 1px var(--color-gray);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    opacity: 0;
    animation: fadeIn 500ms ease-in forwards;
}

#contact .map .img {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#contact .map .icon,
#contact .map .text {
    height: 1rem;

    animation-duration: 1500ms;
}

#contact .map .text {
    font-size: small;
    backdrop-filter: blur(1rem);

    transition: 500ms;
    opacity: 0;
}

#contact .map:hover .text {
    opacity: 1;
}

#contact .container {
    margin: 1rem auto 0;
    table-layout: fixed;
    border-spacing: .5rem 0;
}

#contact .container tr {
    cursor: pointer;
    opacity: 0;
    animation: fadeIn 500ms ease-in forwards;
}

@media (max-width: 82rem) {
    #contact .map .text {
        opacity: 1;
    }
}

@media (max-width: 45rem) {
    #contact .map .text {
        opacity: 1;
    }

    #contact .container {
        border-spacing: .25rem 0;
    }

    #contact .container .text {
        font-size: small;
    }
}