#organization {
    position: relative;
    padding-bottom: 2rem;
    min-height: 100%;
}

#organization .container {
    margin: auto;
    width: calc(100% - 2rem);
    max-width: 40rem;
}

#organization .item {
    display: flex;
    flex-direction: row;

    opacity: 0;
    animation: fadeIn 500ms ease-in forwards;
}

#organization .margin {
    padding-left: 1rem;
    width: 2rem;
    flex-shrink: 0;
}

#organization .margin .up {
    width: 100%;
    height: calc(50% + .5rem);
}

#organization .margin .dn {
    width: 100%;
    height: calc(50% - .5rem);
}

#organization .margin .left {
    border-left: solid 1px black;
}

#organization .margin .bottom {
    border-bottom: solid 1px black;
}

#organization .card {
    cursor: pointer;
    padding: .5rem;
    border: solid 1px black;

    display: flex;
    flex-direction: row;
    flex-grow: 1;

    transition: 500ms;
}

#organization .card:hover {
    box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
}

#organization .card:not(.lv0) {
    margin-top: 1rem;
}

#organization .card.lv0 {
    background-color: black;
    color: white;
}

#organization .card.lv1 {
    background-color: var(--color-gray);
}

#organization .card .title .icon {
    margin-right: .5rem;
}

#organization .card .text {
    flex-grow: 1;
    text-align: right;
    font-size: small;
}

#organization .subsidiary {
    margin: auto;
    margin-top: 2rem;
    width: fit-content;
    max-width: calc(100% - 2rem);

    display: flex;
    flex-direction: row;
    gap: 1rem;

    font-size: small;
    word-break: keep-all;
}

#organization .subsidiary .title {
    flex-shrink: 0;
}