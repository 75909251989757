#greeting {
    position: relative;
    padding-bottom: 2rem;
    min-height: 100%;
}

#greeting .container {
    z-index: 3;
    position: relative;
    padding: 2rem;
    margin: auto;
    width: calc(100% - 2rem);
    max-width: 40rem;

    background-color: white;
    box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
    border: solid 1px var(--color-gray);

    word-break: keep-all;
    white-space: balance;
}

#greeting .container,
#greeting .container>* {
    opacity: 0;
    animation: fadeIn 500ms ease-in forwards;
}

#greeting .icon {
    height: 2rem;
    text-align: center;
}

#greeting .job {
    text-align: right;
    font-size: small;
}

#greeting .ceo {
    text-align: right;
    font-size: x-large;
}