#service {
    position: relative;
    padding-bottom: 2rem;
    min-height: 100%;
}

#service .container {
    margin: auto;
    width: calc(100% - 2rem);
    max-width: 80rem;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

#service .item {
    cursor: pointer;
    position: relative;
    padding: 1rem;
    height: 50dvh;

    border: solid 1px var(--color-gray);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    opacity: 0;
    animation: fadeIn 500ms ease-in forwards;

    overflow: hidden;
}

#service .item:hover {
    box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
}

#service .item .img {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(.5);

    transition: 1500ms;
}

#service .item:hover .img {
    transform: scale(1.2);
}

#service .item .title {
    color: white;
    font-size: x-large;
}

#service .item .text {
    color: white;
    text-align: center;
    word-break: keep-all;
    white-space: balance;
}

@media (max-width: 82rem) {
    #service .container {
        grid-template-columns: repeat(1, 1fr);
    }
}