.nav:not(.main)~#footer {
    transform: none !important;
}

.nav.main~#footer {
    transition: 1000ms;
}

#footer {
    z-index: -1;
    position: relative;
    padding: .5rem;
    height: 15rem;

    border-top: solid 1px var(--color-gray);
    text-align: center;
    font-size: small;
}

#footer .about {
    margin: auto;
    border-spacing: .5rem 0;
}